define("itarp-business-application/helpers/humanize", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(([value]) => {
    if (typeof value !== 'string') return value;
    return (0, _string.capitalize)(value).replaceAll('_', ' ');
  });

  _exports.default = _default;
});